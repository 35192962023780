import { useState } from "react";
import { useNavigate } from 'react-router-dom' ;
import { useSWRConfig } from 'swr';


import LoginForm from '../../common/forms/auth/LoginForm';

function Login() {

  const { cache } = useSWRConfig();

  const navigate = useNavigate();

  const[ btnLoading , setBtnLoading ]=useState(false);

  
  const changeBtnLoading = (state)=>{
    setBtnLoading(state);
  }


 const checkAuthStatus = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/admin/profile`, {
      credentials: 'include'
    });
    if(response.ok){
      return true;
    }else{
      return false;
    }
  } catch (error) {
    return false;
  }
};


const reDirect = async () => {
  // let isLoggedIn = false;
  
  // while (!isLoggedIn) {
  //   isLoggedIn = await checkAuthStatus(); 
    
  //   if (isLoggedIn) {
  //     navigate('/');
  //     break;
  //   }

  //   await new Promise(resolve => setTimeout(resolve, 50));
  
  //}

  cache.clear();
  setTimeout(()=>{
    cache.clear();
    navigate('/');
  } , [50])
}



  return (
    <>
      <div className='h-screen bg-background-100 w-full flex justify-center items-center p-5'>
        <div className='shadow-lg w-full bg-white-100 p-8 rounded-3xl max-w-[500px] text-base'>
          <p className="mb-5 text-[1.2rem] text-gray-600 font-bold ml-1">Login</p>
          <LoginForm reDirec={reDirect} btnLoading={btnLoading} changeBtnLoading={changeBtnLoading} />
        </div>
      </div>
    </>
  );
}

export default Login;
