import { useEffect } from 'react';
import useSWR from 'swr';
import {axiosApiInstance} from '../services/axios';
import {axiosApiForLogin} from '../services/axiosForLogin';
import { useDispatch } from 'react-redux';

import { updateUser } from '../redux/slices/auth';


const useAuth = (forLogin=false)=>{

    const dispatch = useDispatch()

    const { data  , loading , error , mutate} = useSWR('user_me' , async()=>{
        return forLogin ? await axiosApiForLogin.get('/admin/profile') : await axiosApiInstance.get('/admin/profile');
    } , {  revalidateIfStale: false,  revalidateOnFocus : false });

    useEffect(()=>{

        dispatch(updateUser({
            user : data?.data?.data ,
            loading :  !data && !error,
            error : error,
            //mutate : mutate
        }));

    } , [data , loading]);


    return {user : data?.data?.data , error  , loading : !data && error !== undefined , mutate }

}

export default useAuth;