import React, { useState , useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {axiosApiInstance} from '../../../../services/axios';
import {getAuthorsApi} from '../../../../services/authors/authorsService';
import Form from '../../../common/forms/Form';
import {newBlog} from '../../../../services/blogDeepSearch/blogDeepSearchService'
import Loading from '../../../common/loading/Loading';

const NewPost = () => {
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});
    

    useEffect(()=>{
        const getBlogCat = async()=>{
            const res = await axiosApiInstance.get(`/admin/blog-deep-search/categories/all` );
            const resAuthors = await getAuthorsApi();


            if(res.data.status === 'success' && resAuthors.data.status){

                
                setForm( {
                    formItems: [
                             {
                        inputType: "input",
                        config: {
                            label: 'Title',
                            name: "title",
                            containerClasses : 'w-1/2 pr-1'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Slug',
                            name: "slug",
                            containerClasses : 'w-1/2 pl-1'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Seo Description',
                            name: "seoDesc",
                            containerClasses : 'w-full'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "textarea",
                        config: {
                            label: 'Keywords',
                            name: "keywords",
                            containerClasses : 'w-full'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "select-blog-cat",
                        config: {
                            options :  res.data.data.blogCats ,
                            label: 'category',
                            name: "categories",
                            containerClasses : 'w-full'
                        },
                        value: [] ,
                        validation: {
                            notEmptyArray : true
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "ck-editor",
                        config: {
                            label: 'Body',
                            name: "body",
                            containerClasses : 'w-full'
                        },
                        value: "",
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input-file",
                        config: {
                            label: 'Image',
                            name: "images",
                            type: "file",
                            t_label : 'attached_file',
                            containerClasses : 'w-1/2'
                        },
                        value: "",
                        validation: {
                            maxSize: 5000000,
                            isSelected : true ,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Time Read',
                            name: "timeRead",
                            containerClasses : 'w-1/2 pr-1'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "select-blog-publication-status",
                        config: {
                            options : [
                                {id : '-1', title : 'draft'} ,
                                {id : '1', title : 'publication'} ,
                            ] ,
                            label: 'Publication status',
                            name: "publicationStatus",
                            containerClasses : 'w-1/2'
                        },
                        value: "",
                        validation: {
                            selectRequired : true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "selectAuthor",
                        config: {
                            options : resAuthors.data.data,
                            label: 'Author',
                            name: "authors",
                            containerClasses : 'w-1/2'
                        },
                        value: "",
                        validation: {
                            selectRequired : true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                ]
                });
                

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
            setLoading(false);
        }
        getBlogCat();
    } , []);


    const handleSubmitForm = async(formData)=>{

        try{

            //const {data} = await axiosApiInstance.post(`/admin/blog/new` , formData) ;
            const {data} = await newBlog(formData) ;
            setLoadingForm(true);
            if(data.status === 'success'){
                toast.success('Your new post sended successfull');
                navigate('/blog-deep-search')

            }else if(data.status == 'error'){
                toast.error(data.msg);
            }

        }catch(err){
            toast.error(err.response.data.message);
            setLoadingForm(true);
        }

    }


    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>New Deep Search Blog Post</span>
                    </div>

                    {
                        loading ?
                        (
                            <Loading/> 
                        )
                        :
                        (
                            <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" file={true}/>

                        )

                    }
                              
                </div>
            </div>
        </>
    )

}

export default NewPost ;
