import React, { useState } from "react";

const InputFileUploadUpload = ({ allProps }) => {

  // ---------- input file -------------
  const [fileInfo, setFileInfo] = useState("");
  const selectFileHandler = () => {
    let input = document.querySelector("#fileInput");
    input.click();
  };
  // ---------- /input file -------------

  return (
    <div className={`${allProps.config.containerClasses} flex flex-col justify-center`}>
      <div className="flex justify-start items-center mx-[5px]">
        <span className="text-gray-800 font-semibold text-[1rem] mb-2">{allProps.config.label}</span>
      </div>
      {allProps.config.disable ? (
        <>
          <input
            className={`rounded-lg p-2 hidden bg-white-100 border outline-none w-full text-slate-700 text-[0.9rem] font-medium focus:border-sky-500  dark:focus:-2-cyan-500 dark:bg-dark-50 dark:focus:border-cyan-500 dark:placeholder:text-white-50  dark:text-white-100 ${
              !allProps.valid && allProps.used ? "   border-red-500" : "border-gray-200 dark:border-dark-50"
            }`}
            autoComplete="off"
            {...allProps.config}
            onChange={allProps.change}
            value={allProps.value}
            disabled
          ></input>
          <div className="w-10 h-10 p-2 cursor-pointer flex items-center justify-center rounded-lg border border-dotted border-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
          </div>
        </>
      ) : (
        <div className={`flex flex-col items-center`}>
          <input
            id="fileInput"
            className={`mx-auto rounded-lg p-1 hidden bg-white-100 border outline-none w-full text-slate-700 text-[0.9rem] font-medium focus:border-sky-500  dark:focus:-2-cyan-500 dark:bg-dark-100 dark:focus:border-cyan-500 dark:placeholder:text-white-50  dark:text-white-100`}
            autoComplete="off"
            {...allProps.config}
            onChange={allProps.change}
            value={allProps.value}
          ></input>
          <div
            className={`w-[100px] h-[100px] p-[2px] cursor-pointer ${allProps.used && allProps.value !== "" ? "" : "hover:bg-gray-100"} z-[100] flex items-center justify-center rounded-lg border  ${
              !allProps.valid && allProps.used ? " border-dotted border-red-500" : "border-gray-400 dark:border-dark-50 border-dotted"
            } `}
            onClick={(e) => allProps.value == "" && selectFileHandler(e)}
          >
            {allProps.used && allProps.value !== "" ? (
              <div className="w-full h-full flex items-center justify-center relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 absolute right-[1px] top-[1px] cursor-pointer bg-gray-200 hover:bg-gray-300 z-[200]"
                  onClick={allProps.emptyField}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                {allProps?.file?.type.split("/")[0] == "image" ? (
                  <img className="w-full h-auto" src={URL.createObjectURL(allProps?.file)} />
                ) : (
                  <div className="w-full h-full bg-yellow-100 flex items-center justify-center">
                    <span className="font-medium">{allProps?.file?.name.split(".")[allProps?.file?.name.split(".").length - 1]}</span>
                  </div>
                )}
              </div>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
              </svg>
            )}
          </div>
          {allProps.used && allProps.size ? (
            <div className='flex flex-col items-center'>
              <span className="w-fit text-[0.7rem] text-gray-700 mt-2">{allProps?.size / 1000} KB</span>
              <span className="w-fit text-[0.7rem] text-gray-700">{allProps?.filename}</span>
            </div>
          ) : null}
        </div>
      )}
      <div className="flex justify-start h-5 w-full px-[5px]">
        <span className="text-sm text-red-500 font-light text-start">{allProps.errorMsg}</span>
      </div>
    </div>
  );
};

export default InputFileUploadUpload;