import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Link  , useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/auth';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Menus from './Menus';
import {checkPermissions} from '../../utils/checkPermissions';


const Layout = ({}) => {

    const user = useSelector(selectUser);

    const navigate = useNavigate();

    const sidebar = useRef();
    const profileMenu = useRef();
    const btnProfileMenu = useRef();

    const [modalSidebarActive, setModalSidebarActive] = useState(false);
    const [showSide, setShowSide] = useState(true);
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    let today = new Date();
    let persianDate = moment(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`, 'YYYY-M-D')
    .locale('fa')
    .format('dddd, D MMM YYYY ');

    useEffect(() => {
      window.addEventListener('resize', resizeHaandler);

      document.addEventListener("mousedown", closeProfileMenu);
      

      return () => {
          //
      }
    }, []);

    const resizeHaandler = () => {
    if (window.innerWidth < 768) {
        setModalSidebarActive(false);
        setShowSide(true);
    }
    }

    const showSidebarHandler = () => {
    if (showSide) {
        setShowSide(false);
    } else {
        setShowSide(true);
    }
    }

    const toggleSidebarMobileHandler = () => {
    setModalSidebarActive(true);
    }

    const hideSidebarMobileHandler = () => {
    setModalSidebarActive(false);
    }

    const showProfileMenuHandler = () => {
    if (showProfileMenu) {
        setShowProfileMenu(false);
    } else {
        setShowProfileMenu(true);
    }
    }

    const closeProfileMenu = (e) => {
    if (!isEmpty(profileMenu.current)) {
        if (!profileMenu.current.contains(e.target) && !btnProfileMenu.current.contains(e.target)) {
        setShowProfileMenu(false);
        }
    }
    }


    const menu = [
      {
        title: 'Pricing' ,
        icon: "M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
        path: '/contact-form',
        permission : 'Payment' ,
        menues : [
          {
              title: 'Payments',
              icon : 'M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z',
              path: "/pricing/payments" ,
              permission : 'Payment-Read'
          },
        ]
      },
      {
        title: 'Support' ,
        icon: "M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155",
        path: '/',
        permission : 'Ticket,Feedback' ,
        menues : [
          {
            title: 'Tickets', 
            icon : 'M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z',
            path: "/tickets" ,
            permission : 'Ticket-Read' ,
          },
          {
            title: 'Feedback' ,
            icon: "M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z",
            path: '/feedback',
            permission : 'Feedback-Read' ,
          },
      ]
      },
      {
        title: 'Users' ,
        icon: "M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z",
        path: '/',
        permission : 'User,ExclusiveInvitationCode,Subscribe' ,
        menues : [
          {
            title: "All users", 
            icon : 'M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z',
            path: "/users",
            permission : 'User-Read' ,
        },
        {
          title: "Tokens", 
          icon : 'M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z',
          path: "/tokens"
        },
        {
          title: "Exclusive Invitation", 
          icon : 'M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244',
          path: "/exclusive-invitation-codes",
          permission : 'ExclusiveInvitationCode-Read' ,
        },
        {
          title: "Subscribes", 
          icon : 'M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z',
          path: "/subscribes",
          permission : 'Subscribe-Read'
        },
      ]
      },
      {
        title: 'Blog' ,
        icon: "M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z",
        path: '/',
        permission : 'Blog' ,
        menues : [
          {
            title: "All Posts", 
            icon : 'M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z',
            path: "/blog" ,
            permission : 'Blog-Read'
          },
          {
            title: "New Post", 
            icon : 'M12 4.5v15m7.5-7.5h-15',
            path: "/blog/new",
            permission : 'Blog-Write'
          },
          {
            title: "Authors", 
            icon : 'M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125',
            path: "/blog/authors" ,
            permission : 'BlogCategory-Read'
          },
          {
            title: "Categories", 
            icon : 'M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5',
            path: "/blog/categories" ,
            permission : 'BlogCategory-Read'
          },
      ]
      },
      {
        title: 'Blog Deep Search' ,
        icon: "M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z",
        path: '/',
        permission : 'Blog' ,
        menues : [
          {
            title: "All Posts", 
            icon : 'M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z',
            path: "/blog-deep-search" ,
            permission : 'Blog-Read'
          },
          {
            title: "New Post", 
            icon : 'M12 4.5v15m7.5-7.5h-15',
            path: "/blog-deep-search/new",
            permission : 'Blog-Write'
          },
          {
            title: "Categories", 
            icon : 'M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5',
            path: "/blog-deep-search/categories" ,
            permission : 'BlogCategory-Read'
          },
      ]
      },
      {
        title: 'Affiliate' ,
        icon: "M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z",
        path: '/',
        permission : 'Affilicate' ,
        menues : [
          {
            title: "Affiliate Requests", 
            icon : 'M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75',
            path: "/affiliate-requests" ,
            permission : 'Affilicate-Read' ,
          },
      ]
      },
      {
        title: 'Announcements' ,
        icon: "M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0",
        path: '/',
        permission : 'Announcement' ,
        menues : [
          {
            title: "All Announcements", 
            icon : 'M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z',
            path: "/announcements",
            permission : 'Announcement-Read'
          },
          {
            title: "New", 
            icon : 'M12 4.5v15m7.5-7.5h-15',
            path: "/announcements/new" ,
            permission : 'Announcement-Write'
          },
      ]
      },
      {
        title: 'Comment' ,
        icon: "M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z",
        path: '/',
        permission : 'Comment' ,
        menues : [
          {
            title: "All Comments", 
            icon : 'M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155',
            path: "/comments",
            permission : 'Comment-Read'
          },
      ]
      },
      {
        title: 'Permissions' ,
        icon: "M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z",
        path: '/',
        permission : 'Permissions' ,
        menues : [
          {
            title: "Admins", 
            icon : 'M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z',
            path: "/admins"
          },
          {
            title: "Groups", 
            icon : 'M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z',
            path: "/groups"
          },
      ]
      },
      {
        title: 'CRM' ,
        icon: "M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5",
        path: '/',
        permission : 'CRM' ,
        menues : [
          {
            title: "Email Templates", 
            icon : 'M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5',
            path: "/crm/templates/email"
          },
          {
            title: "Telegram Templates", 
            icon : 'M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5',
            path: "/crm/templates/telegram"
          },
          {
            title: "Events", 
            icon : 'M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59',
            path: "/crm/events"
          },
          {
            title: "Send Test Events", 
            icon : 'M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5',
            path: "/crm/events/test/send"
          },
      ]
      },
      {
        title: 'Popups Setting' ,
        icon: "M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5",
        path: '/',
        permission : 'CRM' ,
        menues : [
          {
            title: "Popups", 
            icon : 'M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0',
            path: "/crm/popups"
          },
      ]
      },
    ]



    const [menus, setMenus] = useState(menu);

    if(!user){
      return <></>
    }
  
    return (
    <>
      <div className={`w-full h-full overflow-hidden fixed left-0 top-0 z-50 bg-loadingDark-100 ${modalSidebarActive ? 'block' : 'hidden'} `} onClick={(e) => hideSidebarMobileHandler(e)}>
      </div>
      <div ref={sidebar} className={`h-full pr-[10px] bg-slate-900 rounded-br-lg rounded-tr-lg dark:bg-dark-100 z-60 transition-all duration-500 fixed t-0 md:mr-0 ${modalSidebarActive ? 'mr-0' : '-mr-[100%]'} ${showSide ? 'w-[260px]' : 'w-[78px]'}`}>
        <div className='w-full h-[60px] flex justify-center md:justify-start items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" className={`hidden h-8 min-w-[78px] mr-2 stroke-white-100 dark:stroke-white-100 transition-all duration-500 md:block hover:cursor-pointer ${showSide ? '' : 'rotate-180'} `} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => showSidebarHandler()}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <Link to="/">
              <img className='w-[200px]' src='/images/logo_dark.png' width={200} height={42} alt='logo' layout='raw'/>                               
            </Link>
        </div>
        <ul className={`sidebar-menu w-full h-full  pt-[30px] pb-[150px] ${showSide ? 'overflow-auto' : 'overflow-visible'}`}>
          {
              menus.map((item, index) => {
                {
                  return (
                    <React.Fragment key={index}>
                       {
                          checkPermissions(user.type , user.permissions , item.permission ?? true) ?
                          (
                            <li key={index} className={`list-none transition-all duration-500 relative rounded-tr-3xl rounded-br-3xl mb-[30px] hover:cursor-pointer`}>
                              <Menus showSide={showSide} title={item.title} permission={item.permission} icon={item.icon} menues={item.menues} user={user}/>
                            </li>
                          )
                          :
                          (
                            null
                          )
                        }
                    </React.Fragment>
                  )
                }
              })
            }
        </ul>
      </div>
      <div className={`w-full pl-0 flex flex-col transition-all duration-500 relative ${showSide ? 'md:ml-[260px] md:w-full-260' : 'md:ml-[78px] md:w-full-78'} `}>
        <div className='h-[60px] w-full flex items-center py-1 px-3 justify-between sticky top-0 transition-all duration-500 bg-white-100 dark:bg-dark-100 z-[1000000]'>
          <div className='flex items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" className='h-8 w-8 stroke-slate-700 dark:stroke-white-100 block md:hidden hover:cursor-pointer' fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => toggleSidebarMobileHandler()}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <span className='text-gray-600 text-sm hidden md:block'>
              {persianDate}
            </span>
          </div>
          <div className='flex items-center'>
            <div className='relative'>
              <div className='w-[8px] h-[8px] bg-primary-100 rounded-full absolute top-[-5px] right-[12px]'></div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-5 h-5 mr-3 cursor-pointer stroke-gray-600 hover:stroke-gray-800">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
              </svg>
            </div>
            <span className='text-gray-600 text-[0.7rem] mr-2'>{user.name}</span>
            <div ref={btnProfileMenu} className='h-[53px] pl-2 flex items-center justify-end text-center relative' onClick={showProfileMenuHandler}>
                <div className='hover:cursor-pointer'>
                    <img className='rounded-full w-[42px] h-[42px] border border-primary-100' width="42" height="42" src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${user?.avatar.thumb}`} /> 
                </div>
                <div ref={profileMenu} className={`w-[150px] bg-white-100 absolute top-[50px] right-[15px] transition-all duration-300 border border-slate-100 ${showProfileMenu ? 'block animate-fade-in' : 'hidden'} `}>
                  <ul className='mt-0 p-0'>
                      <li className='w-full p-3 list-none text-[0.8rem] font-normal flex items-center justify-right hover:cursor-pointer hover:bg-slate-100 dark:hover:bg-dark-100' onClick={() => navigate('/')}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                          </svg>
                          Home
                      </li>
                      <li className='w-full p-3 list-none text-[0.8rem] font-normal flex items-center justify-right hover:cursor-pointer hover:bg-slate-100 dark:hover:bg-dark-100' onClick={() => navigate('/logout')}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                          </svg>
                          Logout
                      </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
            <Outlet/> 
      </div>
    </>
  )
}

export default Layout;
