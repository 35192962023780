
export const inputChange  = (e, element , formItems )  =>{

    let updatedForm = [...formItems];
    let updetedElement = { ...updatedForm[element] };

    //console.log(updetedElement)
  
    if(updetedElement.inputType === 'input-file' || updetedElement.inputType === 'inputFileUpload' ){
        
        //console.log(e);
        
        updetedElement.file = e.target.files[0];
        updetedElement.value = e.target.value;
        updetedElement.size = e.target.files[0].size;
        updetedElement.filename = e.target.files[0].name;
        updetedElement.valid = checkValidation({size : updetedElement.size , type:  e.target.files[0].type}, updetedElement.validation ).valid;
        updetedElement.errorMsg = checkValidation({size:updetedElement.size , type :  e.target.files[0].type}, updetedElement.validation ).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
    }else if(updetedElement.inputType === 'checkbox' ){
        updetedElement.value = `${e.target.checked ? true : false}`;
        updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation ).valid;
        updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation ).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
    }else if(updetedElement.inputType === 'myCheckbox' ){
        updetedElement.value = e == 'true' ? 'false' : 'true';
        updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation ).valid;
        updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation ).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
    }else if(updetedElement.inputType === 'faq' ){

        updetedElement.value = e ;
        updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation ).valid;
        updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation ).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;

    }else if (updetedElement.inputType === 'date-input' || updetedElement.inputType === 'date-time-input') {
        updetedElement.value = `${e.year}/${String(e.month).length ===1 ? `0${e.month}` : e.month}/${String(e.day).length === 1 ? `0${e.day}`: e.day} ${e.hour}:${e.minute}:${e.second}`;
        updetedElement.valid = checkValidation(`${e.year}/${e.month}/${e.day} ${e.hour}:${e.minute}:${e.second}`, updetedElement.validation).valid;
        updetedElement.errorMsg = checkValidation(`${e.year}/${e.month}/${e.day} ${e.hour}:${e.minute}:${e.second}`, updetedElement.validation ).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
    }else if(updetedElement.inputType === 'ck-editor' ){
        updetedElement.value = e;
        updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation).valid;
        updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
    }else if(updetedElement.inputType === 'select-blog-cat'  || updetedElement.inputType === 'checkbox-blog-cat' ){
        if(e.action === 'add'){
            let arr = updetedElement.value;
            arr.push(e.id)
            updetedElement.value = arr;
            updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation).valid;
            updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation).msg;
            updetedElement.used = true;
            updatedForm[element] = updetedElement;
        }else if(e.action === 'remove'){
            let arr = updetedElement.value;
            updetedElement.value = arr.filter(item=>{
                return item !== e.id
            });
            updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation).valid;
            updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation).msg;
            updetedElement.used = true;
            updatedForm[element] = updetedElement;
        }
    }else if(updetedElement.inputType === 'select-users-multi-search-api'){

        if(e.action === 'add'){
            let arrValue = updetedElement.value;
            let arrValueFull = updetedElement.valueFull;

            arrValue.push(e.item._id);
            arrValueFull.push(e.item)

            updetedElement.value = arrValue;
            updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation).valid;
            updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation).msg;
            updetedElement.used = true;
            updatedForm[element] = updetedElement;
        }else if(e.action === 'remove'){
            let arrValue = updetedElement.value;
            updetedElement.value = arrValue.filter(item=>{
                return item !== e.id
            });
            let arrValueFull = updetedElement.valueFull;
            updetedElement.valueFull = arrValueFull.filter(item=>{
                return item._id !== e.id
            });
            
            updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation).valid;
            updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation).msg;
            updetedElement.used = true;
            updatedForm[element] = updetedElement;
        }
        
    }else{
        updetedElement.value = e.target.value;
        updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation  ).valid;
        updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation ).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
    } 

    console.log(updatedForm);
    return { formItems: updatedForm } ;
}


export const validationByClick  = (formItems )  =>{
    let updatedForm = [...formItems];
    updatedForm.forEach((item, index) => {
        item.valid = checkValidation(item.value, item.validation , item.config.isdepend , item.config.isdependfield , item.config.isdependvalue , formItems  ).valid;
        item.errorMsg = checkValidation(item.value, item.validation , item.config.isdepend , item.config.isdependfield , item.config.isdependvalue , formItems ).msg;
        item.used = true;
        updatedForm[index] = item;
    });

    //console.log(updatedForm)

    return { formItems: updatedForm } ;
}


const checkValidation = (value, rules  , isdepend=false ,  isdependfield , isdependvalue , form) => {

    if(isdepend){
        let indexDependField = form.findIndex(item=>item.config.name === isdependfield);
        if(form[indexDependField].value !== isdependvalue){
            return {
                valid: true,
                msg: ""
            }
        }
    }


    let isValid = true;
    if (rules.required) {
        if(value){

            isValid = String(value).trim() !== "";
            if (!isValid) {
                return {
                    valid: false,
                    msg: 'Required'
                }
            }
        }else{
            return {
                valid: false,
                msg: 'Required'
            }
        }
    }
    if (rules.passValidator) {
        let re = new RegExp(/^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/ig);
        if (value.trim().length < 6 || !re.test(value)) {
            return {
                valid: false,
                msg: 'passValidator_msg'

            }
        }
    }

    if(rules.isDigit){
        let reDigits = new RegExp(/^\d+$/ig);
        if (!reDigits.test(value)) {
            return {
                valid: false,
                msg: 'please enter a valid number'
            }
        }
    }

    if(rules.isWalletNumber){
        let re = new RegExp(/^\-?[0-9]+(?:\.[0-9]{1,2})?$/ig);
        if (!re.test(value)) {
            return {
                valid: false,
                msg: 'please enter a valid number'
            }
        }
    }


    if (rules.codeValidator) {
        let re = new RegExp(/^(?=.*?[0-9]).{5}$/ig);
        if (value.length !== 5 || !re.test(value)
        ) {
            return {
                valid: false,
                msg: 'code_msg'
            }
        }
    }


    if (rules.maxSize) {
        if (value.size > rules.maxSize) {
            return {
                valid: false,
                msg:  'fileSizeValidator_msg'

            }
        }
    }

    if (rules.isSelected) {
        if (!value) {
            return {
                valid: false,
                msg:  'Please selecte a image'

            }
        }
    }
   
    if (rules.isEmail) {
        let re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ig);
        if (!re.test(value)) {
            return {
                valid: false,
                msg: 'email_msg'
            }
        }

    }

    if (rules.isChecket) {
        if (value === "off") {
            return {
                valid: false,
                msg: 'please select check box'
            }
        }
    }

    if(rules.selectRequired){
        if(value == 0 ){
            return{
                valid : false ,
                msg : 'please select one item'
            }
        }
    }

    if (rules.selectedOrder) {
        if (value === 0) {
            return {
                valid: false,
                msg: 'select_order_msg'
            }
        }
    }

    if (rules.notEmptyArray) {
        if (value.length === 0 || value === '0') {
            return {
                valid: false,
                msg: 'please select one item'
            }
        }
    }

    if (rules.validTypes) {

        console.log(value);
        
        if (rules.validTypes.includes(value?.type)) {
            return {
                valid: false,
                msg:  'file type not valid'
            }
        }

    }
 
    return {
        valid: true,
        msg: ""
    }

}

export const emptyField  = (element , formItems )  =>{

    let updatedForm = [...formItems];
    let updetedElement = { ...updatedForm[element] };

    if(updetedElement.inputType === 'input-file' || updetedElement.inputType === 'inputFileUpload' ){

        updetedElement.file = '';
        updetedElement.value = '';
        updetedElement.size = '';
        updetedElement.filename = '';
        updetedElement.valid = '';
        updetedElement.errorMsg = '';
        updetedElement.used = false;
        updatedForm[element] = updetedElement;

    }else{
        updetedElement.value = updetedElement.value;
        updetedElement.valid = checkValidation(updetedElement.value, updetedElement.validation ).valid;
        updetedElement.errorMsg = checkValidation(updetedElement.value, updetedElement.validation ).msg;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
    } 
    //console.log(updatedForm);
    return { formItems: updatedForm } ;
}