import React , {useState , useEffect , useRef} from 'react';
import parse from "html-react-parser";
import { isEmpty } from 'lodash';


const SelectBlogCat = ({allProps}) => {
//const SelectBlogCat = ({ name , classes , change , value ,  items , icon }) => {

    const elBtn = useRef();
    const el = useRef();

    const [selectedItem , setSelectedItem] = useState([]);

    const [show , setShow] = useState(false);
    const [filteredItem , setFilteredItems] = useState(allProps.config.options);

    const showHandler = ()=>{
        setShow(!show);
        setFilteredItems(allProps.config.options);
    }

    const close = (e) => {
        if (!isEmpty(el.current)) {
            if (!el.current.contains(e.target) && !elBtn.current.contains(e.target)) {
                setShow(false);
            }
        }
    }

    const addChangeHandler = (id)=>{
        allProps.change({action : 'add' , id : id});
        setShow(false);
    }

    const removeChangeHandler = (id)=>{
        allProps.change({action : 'remove' , id : id});
        setShow(false);
    }

    useEffect(() => {

        let selected = allProps.config.options.filter(item=>{
            if(allProps.value.includes(item._id)){
                return true;
            }
        });
        setSelectedItem(selected);
        

        document.addEventListener("mousedown", close);
        return () => {
           document.removeEventListener("mousedown", close);
        }
    }, [allProps.value.length]);


    const searchHandler = (e)=>{
        let filtered = allProps.config.options.filter(item =>{
            if(((item.title.toLowerCase()).trim()).startsWith((e.target.value))){
                return true;
            }
        });
     
        setFilteredItems(filtered);
        
    }



    return (
        <div className={`${allProps.config.containerClasses} flex flex-col justify-center mb-5`} >    
            <div className='flex justify-start items-center mx-[5px]' >
                <span className='text-gray-800 font-semibold text-[1rem] mb-1'>{allProps.config.label}</span>
            </div>
            <div className={`w-full min-h-[40px] p-1 border rounded-lg relative text-gray-700 text-base transition-all duration-500 ${!allProps.valid && allProps.used ? 'border-red-500' : 'border-gray-700'} ${allProps.classes}`}>
                <div className='flex h-full items-center justify-between cursor-pointer hover:border-red-500 z-[10]' onClick={(e)=>showHandler(e)} ref={elBtn}>
                    <div className='flex justify-start items-center'>
                    <input className='hidden' name={allProps.config.name} value={allProps.value}></input> 
                    {
                        selectedItem.length ? 
                        (
                            selectedItem.map((item , index)=>(
                                <div key={index} className={`flex items-center p-2 bg-gray-200 rounded-md ${index !== (selectedItem.length-1) ? 'mr-2' : ''}`}>
                                    <span className='text-[1rem] text-gray-700'>{item.title}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-4 h-4 ml-2 stroke-gray-400 hover:stroke-gray-500" onClick={()=>removeChangeHandler(item._id)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            ))
                        )
                        :
                        (
                            null
                        )
                    } 
                    </div> 
                    
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={` w-4 h-4 ml-3 duration-200 ${show ? 'rotate-180' : '' } `}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>

                    </div>
                {
                    show ?
                    (
                        <div className='w-full absolute top-[40px] bg-white-100 left-[0px] border border-gray-700 bg-white-100 z-[20000]' ref={el} >
                            <div className='flex items-center rounded-3xl mx-[5px] my-[10px] bg-gray-100 relative'>
                                <input className='w-full h-[40px] rounded-3xl bg-gray-100 pl-[10px] text-[0.9rem] leading-800 font-dana outline-none focus:border-backgroundInput-100 ' type="text"  onChange={(e)=>searchHandler(e)}/>
                                <div className='border-none w-[50px] rounded-r-[40px] flex justify-center items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-white-100 h-4 w-3 stroke-gray-500" fill="none" viewBox="0 0 24 24" strokeWidth={3}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className='max-h-[200px] overflow-y-auto'>
                            {
                                filteredItem.map((item , index)=>(
                                    <div className='flex items-center hover:bg-gray-100 cursor-pointer px-3 py-1' key={index} onClick={()=>addChangeHandler(item._id)}>
                                        {allProps.icon ? parse(item.content.icon) : null}
                                        <span className='text-[1rem] font-medium text-gray-700'>{item.title}</span>
                                    </div>
                                ))
                            }
                            </div>
                        
                        </div>

                    ):
                    (
                        null
                    )
                }
            </div>
            {
                allProps.errorMsg !== '' ?
                (
                    <div className='flex justify-between h-5 w-full px-[5px]'>
                        <span className='text-sm text-red-500 font-light'>{allProps.errorMsg}</span>
                    </div>
                )
                :
                (
                    null
                )
            } 

        </div>
    
    )
}

export default SelectBlogCat;