import React  from 'react';


const CheckboxBlogCat = ({allProps}) => {


    const changeCheck = (e)=>{
        let itemClicked = e.target.value;
    
        let isExistInValue = allProps.value.some(item =>{
            console.log(item)
            return item == itemClicked;
        });


        if(!isExistInValue){
            allProps.change({action : 'add' , id : itemClicked});
        }else{
            allProps.change({action : 'remove' , id : itemClicked});
        }

    }

    return (
        <div className={`${allProps.config.containerClasses} flex flex-col justify-center mb-5`} >
            <div className='flex justify-start items-center mx-[5px]' >
                <span className='text-gray-700 font-light text-[0.8rem]'>{allProps.config.label}</span>
            </div>
            <div className={`w-full border rounded-lg p-4 relative dark:bg-dark-100 text-slate-500 dark:text-white-100 text-base transition-all duration-500 focus:border-sky-500 ${!allProps.valid && allProps.used ? 'border-red-500' : 'border-gray-200 dark:border-dark-100'} ${allProps.classes}`}>
                {
                    
                    allProps.config.options.map(item=>(
                        <div className={`w-full checkbox-wrapper font-roboto ml-[10px]`}>
                        <label className="flex justify-start items-center h-full text-gray-800 dark:text-white-100 font-semibold text-[0.9rem]">
                            <input className={"checked"} type="checkbox" checked={allProps.value.includes(item.id) ? true : false} onChange={(e) => changeCheck(e)}  value={item.id} />
                            <span className='ml-2'>{item.title}</span>
                        </label>
                        </div>
                    ))
                } 
                    
            </div>
            {
                allProps.errorMsg !== '' ?
                (
                    <div className='flex justify-between h-5 w-full px-[5px]'>
                        <span className='text-sm text-red-500 font-light'>{allProps.errorMsg}</span>
                    </div>
                )
                :
                (
                    null
                )
            }

        </div>
    
    )
}

export default CheckboxBlogCat;