import React , {useState}  from 'react';

const MyFAQ = (props) => {

    const [afqs , setAfqs] = useState(props.value ?? []);

    const addFaqHandler = ()=>{
        let newAfqs = [...afqs];
        let newAfq = {
            question: "" ,
            answer: ""
        }
        newAfqs.push(newAfq);
        console.log(newAfqs)
        setAfqs(newAfqs);

        props.change(newAfqs);
    }


    const removeFaqHandler = (index)=>{
        let oldAfqs = [...afqs];
        let newAfqs = oldAfqs.filter((item , indexItrm)=>(indexItrm !== index));
        setAfqs(newAfqs);

        props.change(newAfqs);
    }


    const changeAfqsHandler = (index , key , e)=>{
        let newAfqs = [...afqs];
        newAfqs[index][key] = e.target.value;
        setAfqs(newAfqs);

        props.change(newAfqs);
    }


    return (
        <div className={`${props?.config?.containerClasses} flex flex-col justify-start items-start mb-5`} >

            <span className='ml-2 text-gray-700 font-bold text-[0.9rem] block'>{props.config.label}</span>

            <input className={`hidden`} name={props.config.name} value={JSON.stringify(props.value)}></input>
            
            {
                afqs && afqs.map((faq , index)=>(
                    <div className='w-full mb-5 border border-dashed border-gray-700 p-3 rounded-md' key={index}>
                        <div className='flex justify-start items-center mx-[5px]' >
                            <span className='text-gray-700 font-semibold text-[0.8rem] text-gray-700'>Question ({index+1})</span>
                        </div>
                        <input name="faq-q" className={`rounded-md px-1 py-2 bg-white-100 border outline-none w-full text-gary-700 text-[0.9rem] font-normal focus:border-primary-100 border-gray-700`} autoComplete="off" onChange={(e)=>changeAfqsHandler(index , 'question' , e)} value={faq.question}></input>

                        <div className='flex justify-start items-center mx-[5px]' >
                            <span className='text-gray-700 font-semibold text-[0.8rem] text-gray-700'>Answer ({index+1})</span>
                        </div>
                        <textarea name="faq-a" rows={props.config.rows ?? '4'} className={`rounded-lg p-1 border outline-none w-full text-[0.9rem] font-normal text-gray-700 font-medium focus:border-primary-100 border-gray-700`} autoComplete="off" onChange={(e)=>changeAfqsHandler(index , 'answer' , e)} value={faq.answer}></textarea>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="stroke-gray-700" className="w-[24px] h-[24px] stroke-gray-700 mt-3 hover:stroke-gray-500 cursor-pointer" onClick={()=>removeFaqHandler(index)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </div>
                ))
            }

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-[24px] h-[24px] stroke-gray-700 mt-3 hover:stroke-gray-500 cursor-pointer" onClick={addFaqHandler}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>

                    
        </div>
    
    )
}

export default MyFAQ;