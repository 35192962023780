import { Form , withFormik } from "formik";
import { ToastContainer , toast } from 'react-toastify';



import Input from "../../inputs/Input";
import Button from "../../button/Button" ;
import { loginSchema } from '../../../validation/loginSchema' ;
import {login} from '../../../../services/auth/authService';

import 'react-toastify/dist/ReactToastify.css';

const innerLoginForm = (props) =>{


    return(
        <Form>
            <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored" />

            <Input
                label = "Email"
                name = "email"
                type = "text" 
                placeholder = "Enter your Email"
            />

            <Input
                label = "Password"
                name = "password"
                type = "password" 
                placeholder = "Enter your Password"
            />

            <div className='flex flex-wrap flex-col items-end mb-[16px]'>
                <Button submit={true} loading={props.btnLoading} classes="p-2 bg-primary-100 w-full border border-primary-100 hover:bg-transparent hover:text-primary-100 text-gray-700 font-semibold">
                    Login
                </Button>
            </div>
           
      </Form>
    )

} 


const LoginForm = withFormik({

       mapPropsToValues : props =>({
                email : props.email?? '' ,
                password : props.password?? ''
            }),
        
            validationSchema : loginSchema ,
            handleSubmit : async(values , {props}) =>{

                try{

                    props.changeBtnLoading(true);
    
                    const res = await login(values);
    
                    if(res?.data.status === 'success'){
                        props.changeBtnLoading(false);
                        props.reDirec();
            
                    }

                }catch(err){
                    props.changeBtnLoading(false);
                    toast.error(err.response.data.msg);
            
                }
      
                 
            }
        
    })(innerLoginForm)
    



export default LoginForm;


