import React from 'react';
import {Routes , Route } from 'react-router-dom';

import Layout from './layout/Layout';
import Index from './pages/index/Index';
//----------------------- pricing -------------------------------
import Plans from './pages/pricing/plans/Plans';
import Payments from './pages/pricing/payments/Payments';
//----------------------- users -------------------------------
import Users from './pages/users/Users';
import Tokens from './pages/tokens/Tokens';
import UserDetail from './pages/userDetail/UserDetail';
import ExclusiveInvitationCode from './pages/exclusiveInvitationCode/index/ExclusiveInvitationCode';
import NewExclusiveInvitationCode from './pages/exclusiveInvitationCode/new/NewCode';
import EditExclusiveInvitationCode from './pages/exclusiveInvitationCode/edit/EditCode';
//----------------------- orders -------------------------------
import Tickets from './pages/tickets/Tickets';
import SingleTicket from './pages/singleTicket/SingleTicket';
//----------------------- affiliate Req -------------------------------
import AffiliateReqs from './pages/affiliateReqs/AffiliateReqs';
import SingleAffiliateReq from './pages/singleAffiliateReq/SingleAffiliateReq';
//----------------------- subscribes -------------------------------
import Subscribes from './pages/subscribes/Subscribes';
//----------------------- feedback -------------------------------
import Feedback from './pages/feedback/Feedback';
import SingleFeedback from './pages/singleFeedback/SingleFeedback';
//----------------------- comments -------------------------------
import AllComments from './pages/comments/allComments/AllComments';
import EditComment from './pages/comments/editComment/EditComment';
//----------------------- blog -------------------------------
import NewPost from './pages/blog/newPost/NewPost';
import AllPosts from './pages/blog/allPosts/AllPosts';
import EditPost from './pages/blog/editPost/EditPost';
import BlogCat from './pages/blog/blogCat/BlogCat';
import NewBlogCat from './pages/blog/newBlogCat/NewBlogCat';
import EditBlogCat from './pages/blog/editBlogCat/EditBlogCat';
import Authors from './pages/blog/authors/Authors';
import NewAuthor from './pages/blog/newAuthor/NewAuthor';
import EditAuthor from './pages/blog/editAuthor/EditAuthor';
//----------------------- blog deep search -------------------------------
import BlogDeepSearchCat from './pages/blogDeepSearch/blogCat/BlogCat';
import NewBlogDeepSearchCat from './pages/blogDeepSearch/newBlogCat/NewBlogCat';
import EditBlogDeepSearchCat from './pages/blogDeepSearch/editBlogCat/EditBlogCat';
import NewDeepSearchPost from './pages/blogDeepSearch/newPost/NewPost';
import AllDeepSearchPosts from './pages/blogDeepSearch/allPosts/AllPosts';
import EditDeepSearchPost from './pages/blogDeepSearch/editPost/EditPost';


//----------------------- save excel -------------------------------
import SaveExcel from './pages/saveExel/saveExcel/SaveExel';
//----------------------- Announcements -------------------------------
import AllAnnouncements from './pages/announcements/allAnnouncements/AllAnnouncements';
import NewAnnouncement from './pages/announcements/newAnnouncement/NewAnnouncement';
import EditAnnouncements from './pages/announcements/editAnnouncements/EditAnnouncements';
//----------------------- permissions -------------------------------
import Groups from './pages/permissions/groups/index/Groups';
import NewGroup from './pages/permissions/groups/newGroup/NewGroup';
import EditGroup from './pages/permissions/groups/editGroup/EditGroup';
import Admins from './../components/pages/permissions/admins/index/admins';
import NewAdmin from '../components/pages/permissions/admins/newAdmin/NewAdmin';
import EditAdmin from '../components/pages/permissions/admins/editAdmin/EditAdmin';
//----------------------- crm -------------------------------
import EmailTemplates from '../components/pages/crm/emailTemplates/index/Index';
import NewEmailTemplate from '../components/pages/crm/emailTemplates/new/New';
import EditEmailTemplate from '../components/pages/crm/emailTemplates/edit/Edit';
import TelegramTemplates from '../components/pages/crm/telegramTemplates/index/Index';
import NewTelegramTemplate from '../components/pages/crm/telegramTemplates/new/New';
import EditTelegramTemplate from '../components/pages/crm/telegramTemplates/edit/Edit';
import Events from '../components/pages/crm/events/index/Index';
import NewEvents from '../components/pages/crm/events/new/New';
import EditEvent from '../components/pages/crm/events/edit/Edit';
import TestSendEvents from '../components/pages/crm/events/testSend/Index';
import EventsLogs from './pages/crm/events/logs/Logs';
//----------------------- Popups -------------------------------
import Popups from '../components/pages/crm/popups/index/Index';
import NewPopup from '../components/pages/crm/popups/new/New';
import EditPopup from '../components/pages/crm/popups/edit/Edit';
//----------------------- common -------------------------------
import About from './pages/about/About';
import NotFound from './pages/notFound/NotFound';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
//import { selectUser } from '../redux/slices/auth';
import IsAuth from '../components/common/isAuth/IsAuth';
import AuthorizedRoute from '../components/common/authorizedRoute/AuthorizedRoute';


const Forvest = () => {

    return(
        <Routes>
          <Route path="/"  element={
            <IsAuth forLogin={false}>
              <Layout />
            </IsAuth>
          }>
            <Route index element={(<Index/>)}/>
            <Route path="/saveExcel/indicator" element={<SaveExcel/>} />
            {/*------- payments ------------*/} 
            <Route path="/pricing/plans" element={<Plans/>}/>
            <Route path="/pricing/payments" element={
              <AuthorizedRoute permission='Payment-Read'>
                <Payments/>
              </AuthorizedRoute>
            }/>
            {/*------- /payments ------------*/} 

            {/*-------- affiliate ------------*/} 
            <Route path="/affiliate-requests" element={
            <AuthorizedRoute permission='Affilicate-Read'>
              <AffiliateReqs/>
            </AuthorizedRoute>
            }/>
            <Route path="/affiliate-requests/:id" element={
              <AuthorizedRoute permission='Affilicate-Read'>
                <SingleAffiliateReq/>
              </AuthorizedRoute>
            }/>
            {/*-------- /affiliate ------------*/} 

            {/*-------- subscribes ------------*/} 
            <Route path="/subscribes" element={
              <AuthorizedRoute permission='Subscribe-Read'>
                <Subscribes/>
              </AuthorizedRoute>
            }/>
            {/*-------- /subscribes ------------*/} 

            {/*------- feedback ------------*/} 
            <Route path="/feedback" element={
              <AuthorizedRoute permission='Feedback-Read'>
                <Feedback/>
              </AuthorizedRoute>
            }/>
            <Route path="/feedback/:id" element={
              <AuthorizedRoute permission='Feedback-Read'>
                <SingleFeedback/>
              </AuthorizedRoute>
            }/>
            {/*------- /feedback ------------*/} 

            
            {/*------- tickets ------------*/} 
            <Route path="/tickets" element={
              <AuthorizedRoute permission='Ticket-Read'>
                <Tickets/>
              </AuthorizedRoute>
            }/>
            <Route path="/tickets/:id" element={
              <AuthorizedRoute permission='Ticket-Modify'>
                <SingleTicket/>
              </AuthorizedRoute>
            }/>
            {/*------- /tickets ------------*/} 


            {/*------- /user ------------*/} 
            <Route path="/users" element={
              <AuthorizedRoute permission='User-Read'>
                <Users/>
              </AuthorizedRoute>
            }/>
            <Route path="/users/:id" element={
            <AuthorizedRoute permission='User-Modify'>
              <UserDetail/>
            </AuthorizedRoute>
            }/>

            {/*------- /users ------------*/} 


            {/*------- ExclusiveInvitationCode ------------*/} 
            <Route path="/exclusive-invitation-codes" element={
              <AuthorizedRoute permission='ExclusiveInvitationCode-Read'>
                <ExclusiveInvitationCode/>
              </AuthorizedRoute>
            }/>
            <Route path="/exclusive-invitation-codes/new" element={
              <AuthorizedRoute permission='ExclusiveInvitationCode-Write'>
                <NewExclusiveInvitationCode/>
              </AuthorizedRoute>
            }/>
            <Route path="/exclusive-invitation-codes/:id" element={
              <AuthorizedRoute permission='ExclusiveInvitationCode-Modify'>
                <EditExclusiveInvitationCode/>
              </AuthorizedRoute>
            }/>
            {/*------- /ExclusiveInvitationCode ------------*/} 

            <Route path="/tokens" element={<Tokens/>} />

            {/*------- blog ------------*/} 
            <Route path="/blog" element={
            <AuthorizedRoute permission='Blog-Read'>
              <AllPosts/>
            </AuthorizedRoute>
            }/>
            <Route path="/blog/:id" element={
              <AuthorizedRoute permission='Blog-Modify'>
                <EditPost/>
              </AuthorizedRoute>
            }/>
            <Route path="/blog/new" element={
              <AuthorizedRoute permission='Blog-Write'>
                <NewPost/>
              </AuthorizedRoute>
            }/>
            <Route path="/blog/categories" element={
              <AuthorizedRoute permission='BlogCategory-Read'>
                <BlogCat/>
              </AuthorizedRoute>
            }/>
            <Route path="/blog/categories/new" element={
              <AuthorizedRoute permission='BlogCategory-Write'>
                <NewBlogCat/>
              </AuthorizedRoute>
            }/>
            <Route path="/blog/categories/:id" element={
              <AuthorizedRoute permission='BlogCategory-Modify'>
                <EditBlogCat/>
              </AuthorizedRoute>
            }/>
            <Route path="/blog/authors" element={
              <AuthorizedRoute permission='BlogCategory-Read'>
                <Authors/>
            </AuthorizedRoute>
            }/>
            <Route path="/blog/authors/new" element={
              <AuthorizedRoute permission='BlogCategory-Read'>
                <NewAuthor/>
            </AuthorizedRoute>
            }/>
            <Route path="/blog/authors/:id" element={
              <AuthorizedRoute permission='BlogCategory-Modify'>
                <EditAuthor/>
              </AuthorizedRoute>
            }/>
            {/*------- /blog ------------*/}

            {/*------- blog deep search ------------*/}
            <Route path="/blog-deep-search/categories" element={
              <AuthorizedRoute permission='BlogCategory-Read'>
                <BlogDeepSearchCat/>
              </AuthorizedRoute>
            }/>

            <Route path="/blog-deep-search/categories/new" element={
              <AuthorizedRoute permission='BlogCategory-Write'>
                <NewBlogDeepSearchCat/>
              </AuthorizedRoute>
            }/>

            <Route path="/blog-deep-search/categories/:id" element={
              <AuthorizedRoute permission='BlogCategory-Modify'>
                <EditBlogDeepSearchCat/>
              </AuthorizedRoute>
            }/>

            <Route path="/blog-deep-search/new" element={
              <AuthorizedRoute permission='Blog-Write'>
                <NewDeepSearchPost/>
              </AuthorizedRoute>
            }/>


            <Route path="/blog-deep-search" element={
              <AuthorizedRoute permission='Blog-Read'>
                <AllDeepSearchPosts/>
            </AuthorizedRoute>
            }/>

            <Route path="/blog-deep-search/:id" element={
              <AuthorizedRoute permission='Blog-Modify'>
                <EditDeepSearchPost/>
              </AuthorizedRoute>
            }/>


            {/*------- /blog deep search------------*/}


            {/*------- announcements ------------*/} 
            <Route path="/announcements" element={
              <AuthorizedRoute permission='Announcement-Read'>
                <AllAnnouncements/>
              </AuthorizedRoute>
            }/>
            <Route path="/announcements/new" element={
              <AuthorizedRoute permission='Announcement-Write'>
                <NewAnnouncement/>
              </AuthorizedRoute>
            }/>
            <Route path="/announcements/:id" element={
              <AuthorizedRoute permission='Announcement-Modify'>
                <EditAnnouncements/>
              </AuthorizedRoute>
            }/>
            {/*------- /announcements ------------*/} 
            <Route path="/comments" element={
              <AuthorizedRoute permission='Comment-Read'>
                <AllComments/>
              </AuthorizedRoute>
            }/>
            <Route path="/comments/:id" element={
              <AuthorizedRoute permission='Comment-Modify'>
                <EditComment/>
              </AuthorizedRoute>
            }/>
            {/*------- /announcements ------------*/} 
            {/*------- permissions ------------*/} 
            <Route path="/groups" element={
              <AuthorizedRoute>
                <Groups/>
              </AuthorizedRoute>
            }/>
            <Route path="/groups/new" element={
              <AuthorizedRoute>
                <NewGroup/>
              </AuthorizedRoute>
            }/>
            <Route path="/groups/:id" element={
              <AuthorizedRoute>
                <EditGroup/>
              </AuthorizedRoute>
            }/>
            <Route path="/admins" element={
              <AuthorizedRoute>
                <Admins/>
              </AuthorizedRoute>
            }/>
            <Route path="/admins/new" element={
              <AuthorizedRoute>
                <NewAdmin/>
              </AuthorizedRoute>
            }/>
            <Route path="/admins/:id" element={
              <AuthorizedRoute>
                <EditAdmin/>
              </AuthorizedRoute>
            }/>
            {/*------- /permissions ------------*/} 

            {/*------- crm ------------*/}
            <Route path="/crm/templates/email" element={
              <EmailTemplates/>
            }/>
            <Route path="/crm/templates/email/new" element={
              <NewEmailTemplate/>
            }/>
              <Route path="/crm/templates/email/:id" element={
              <EditEmailTemplate/>
            }/>
             <Route path="/crm/templates/telegram" element={
              <TelegramTemplates/>
            }/>
            <Route path="/crm/templates/telegram/new" element={
              <NewTelegramTemplate/>
            }/>
              <Route path="/crm/templates/telegram/:id" element={
              <EditTelegramTemplate/>
            }/>
            <Route path="/crm/events" element={
              <Events/>
            }/>
            <Route path="/crm/events/new" element={
              <NewEvents/>
            }/>
              <Route path="/crm/events/test/send" element={
              <TestSendEvents/>
            }/>  
            <Route path="/crm/events/:id" element={
              <EditEvent/>
            }/>
            <Route path="/crm/events/history/:id" element={
              <EventsLogs/>
            }/>
            {/*------- /crm ------------*/}

            {/*------- popups ------------*/}
            <Route path="/crm/popups" element={
              <Popups/>
            }/>
            <Route path="/crm/popups/new" element={
              <NewPopup/>
            }/>
            <Route path="/crm/popups/:id" element={
              <EditPopup/>
            }/>
             
            {/*------- /popups ------------*/}

            <Route path="/about" element={<About/>} />
            <Route path="*" element={<NotFound/>} />
          </Route>
          <Route path="/login" element={ 
            <IsAuth forLogin={true}>
              <Login />
            </IsAuth>
            }
          />
          <Route path="/logout" element={ 
            <IsAuth forLogin={false}>
              <Logout />
            </IsAuth>
          }
          />
        </Routes>
    );
}

export default Forvest;