import {axiosApiInstance} from '../axios';

export const newBlog = (formData) =>{
    return axiosApiInstance.post(`/admin/blog-deep-search/new` , formData);
}

export const singleBlog = (id) =>{
    return axiosApiInstance.get(`/admin/blog-deep-search/edit/${id}`);
}

export const editBlog = (id , formData) =>{
    return axiosApiInstance.put(`/admin/blog-deep-search/${id}` , formData);
}

export const removeBlog = (id) =>{
    return axiosApiInstance.delete(`/admin/blog-deep-search/${id}`);
}


