import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {newAuthors} from '../../../../services/authors/authorsService';
import Form from '../../../common/forms/Form';


const NewAuthor = () => {

    const Navigate = useNavigate();

    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState( {
        formItems: [
            {
                inputType: "input",
                config: {
                    label: 'Name',
                    name: "name",
                    containerClasses:'w-full'
                },
                value: "",
                validation: {
                    required: true,
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "textarea",
                config: {
                    label: 'Bio',
                    name: "bio",
                    containerClasses : 'w-full'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "input",
                config: {
                    label: 'Linkedin',
                    name: "linkedin",
                    containerClasses:'w-full'
                },
                value: "",
                validation: {
                    required: false
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "input",
                config: {
                    label: 'Insta',
                    name: "insta",
                    containerClasses:'w-full'
                },
                value: "",
                validation: {
                    required: false
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "input",
                config: {
                    label: 'Telegram',
                    name: "telegram",
                    containerClasses:'w-full'
                },
                value: "",
                validation: {
                    required: false
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "input",
                config: {
                    label: 'Whatsapp',
                    name: "whatsapp",
                    containerClasses:'w-full'
                },
                value: "",
                validation: {
                    required: false
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "input-file",
                config: {
                    label: 'Image',
                    name: "avatar",
                    type: "file",
                    t_label : 'attached_file',
                    containerClasses:'w-full'
                },
                value: "",
                validation: {
                    maxSize: 5000000,
                    isSelected : true ,
                },
                valid: false,
                errorMsg: "",
                used: false
            },
        ]
    });
        

    const handleSubmitForm = async(formData)=>{

        try {

            setLoadingForm(true);
            const {data} = await newAuthors(formData);            
            setLoadingForm(false); 
            if(data.status === 'success'){
                toast.success('New author created successfull');
                Navigate('/blog/authors');
    
            }else if(data.status == 'error'){
                toast.error(data.msg);
            }
            
        } catch (err) {
            toast.error(err.response.data.message);
            setLoadingForm(false);
        }
    }

    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>New Author</span>
                    </div>
                    <div className='w-full dark:bg-dark-100 p-3 mb-2 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                        <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle={'Apply'} file={true}/>
                    </div>
                </div>
                     
            </div>
        </>
    )

}

export default NewAuthor ;
