import React, { useState , useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {singleAuthor , editAuthor} from '../../../../services/authors/authorsService';
import FormEditAuthor from '../../../common/forms/FormEditAuthor';
import Loading from '../../../common/loading/Loading';


const EditAuthor = () => {

    const {id} = useParams();

    const Navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [author , setAuthor ] = useState({});
    const [ form , setForm ]= useState();



    useEffect(()=>{

        const getAuthor = async()=>{
            const res = await singleAuthor(id);
            if(res.data.status === 'success'){
                setAuthor(res.data.data);
                setForm( {
                    formItems: [
                        {
                            inputType: "input",
                            config: {
                                label: 'Name',
                                name: "name",
                                containerClasses:'w-full'
                            },
                            value: res.data.data.name,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "textarea",
                            config: {
                                label: 'Bio',
                                name: "bio",
                                containerClasses : 'w-full'
                            },
                            value: res.data.data.bio,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input",
                            config: {
                                label: 'Linkedin',
                                name: "linkedin",
                                containerClasses:'w-full'
                            },
                            value: res.data.data.linkedin,
                            validation: {
                                required: false
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input",
                            config: {
                                label: 'Insta',
                                name: "insta",
                                containerClasses:'w-full'
                            },
                            value: res.data.data.insta,
                            validation: {
                                required: false
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input",
                            config: {
                                label: 'Telegram',
                                name: "telegram",
                                containerClasses:'w-full'
                            },
                            value: res.data.data.telegram,
                            validation: {
                                required: false
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input",
                            config: {
                                label: 'Whatsapp',
                                name: "whatsapp",
                                containerClasses:'w-full'
                            },
                            value: res.data.data.whatsapp,
                            validation: {
                                required: false
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input-file",
                            config: {
                                label: 'Image',
                                name: "avatar",
                                type: "file",
                                t_label : 'attached_file',
                                containerClasses:'w-full'
                            },
                            value: "",
                            validation: {
                                maxSize: 5000000,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                ]
                });

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }

            setLoading(false);

        }
        getAuthor();

    } , []);
        

    const handleSubmitForm = async(formData)=>{
        try {

            setLoadingForm(true);

            const {data} = await editAuthor(id , formData) ;
            setLoadingForm(false);

            if(data.status === 'success'){
                toast.success('Author edited successfull');
                Navigate('/blog/authors');
    
            }else if(data.status == 'error'){
                toast.error(data.msg);
            }
            
        } catch (err) {
            setLoadingForm(false);
            toast.error(err.response.data.message);
        }

    }


    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>Edit blog category</span>
                    </div>
                    {
                        loading ?
                        (
                            <Loading/>

                        )
                        :
                        (
                            <div className='w-full dark:bg-dark-100 p-3 mb-2 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                                <FormEditAuthor initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle={'Edit'} file={true} img={author.avatar.original}/>
                            </div>

                        )

                    }
                   
                </div>
                     
            </div>
        </>
    )

}

export default EditAuthor ;
