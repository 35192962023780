import React, { useState } from 'react';

import { sendEventTest } from '../../../../services/crm/eventService';
import Form from '../../forms/Form';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ModalSendEventTest = (props) => {


    const [loading , setLoading] = useState(false);


    const close = async (e) => {
        props.close();
    }


    const handleSubmitForm = async(formData)=>{

        try{

            if(props.eventItem.group == 'GENERAL'){

                formData.ids = formData.all == 'true' ? '$all' : formData.ids ;
                delete formData.all;
            }
            
            setLoading(true);

            const {data} = await sendEventTest(formData , props.eventItem.group) ;
            if(data.status === 'success' && data.events > 0){
                setLoading(false); 
                toast.success('Test Event Sended Successfully');
                props.close();
            }else if(data.events === 0){
                setLoading(false);
                toast.error('No event has been set for this group');
            }else if(data.status == 'error'){
                setLoading(false);
                toast.error(data.msg);
            }

        }catch(err){
            console.log(err);
        }
      
    }


    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            {props.show ? (<div className='w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100 ' onClick={() => close()}></div>) : null}
            <div className={`w-4/5 h-fit max-w-[650px] fixed z-[5000000] bg-white-100 dark:bg-dark-100 rounded-3xl left-0 right-0 top-0 bottom-0 m-auto  ${props.show ? 'animate-my-fade-down block' : 'hidden'}`}>
                <div className='w-full h-full flex flex-col justify-center items-center p-5'>
                        <form className="w-full flex flex-col">
                            <div className='flex justify-between mb-5'>
                                <h1 className="font-bold text-gray-700 text-[1.2rem]">
                                    {`Send Test Event ${props.eventItem.group}`}
                                </h1>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer hover:opacity-80" onClick={() => close()}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <Form initForm={props.form} submit={handleSubmitForm} loading={loading} submitTitle="Send" />
                        </form>
                </div>
            </div>
        </>
    )
}

export default ModalSendEventTest;